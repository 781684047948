import '../../styles/pages/spring-2020/one-pitch-at-a-time.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import YouTube from 'react-youtube';
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';
import DownArrow from '../../components/downarrow/downarrow.js'
import Gallery from "react-photo-gallery";
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { withPrefix } from 'gatsby'
import BaseballIntro from '../../components/baseball-intro/baseball-intro.js'


import byTheNumbers from '../../images/spring-2020/one-pitch-at-a-time/baseball-by-the-numbers.jpg';
import Title from '../../images/spring-2020/one-pitch-at-a-time/one-pitch-at-a-time-title.png';
import HeroBG from '../../images/spring-2020/one-pitch-at-a-time/spread.jpg';
import Garneau from '../../images/spring-2020/one-pitch-at-a-time/garneau.jpg';
import BaseballRendering from '../../images/spring-2020/one-pitch-at-a-time/baseball-rendering.jpg';
// import SoftballRendering from '../../images/spring-2020/one-pitch-at-a-time/softball-rendering.jpg';
import allAmericans from '../../images/spring-2020/one-pitch-at-a-time/all-americans.svg';
import alumni from '../../images/spring-2020/one-pitch-at-a-time/alumni.svg';
import bigWest from '../../images/spring-2020/one-pitch-at-a-time/big-west.svg';
import championships from '../../images/spring-2020/one-pitch-at-a-time/championships.svg';
import coachAwards from '../../images/spring-2020/one-pitch-at-a-time/coach-awards.svg';
import colors from '../../images/spring-2020/one-pitch-at-a-time/colors.svg';
import drafted from '../../images/spring-2020/one-pitch-at-a-time/drafted.svg';
import f from '../../images/spring-2020/one-pitch-at-a-time/f.svg';
import goldenSpikes from '../../images/spring-2020/one-pitch-at-a-time/golden-spikes.svg';
import headCoach from '../../images/spring-2020/one-pitch-at-a-time/head-coach.svg';
import ncaa from '../../images/spring-2020/one-pitch-at-a-time/ncaa.svg';
import nickname from '../../images/spring-2020/one-pitch-at-a-time/nickname.svg';
import stadium from '../../images/spring-2020/one-pitch-at-a-time/stadium.svg';
import winningSeasons from '../../images/spring-2020/one-pitch-at-a-time/winning-seasons.svg';
import worldSeries from '../../images/spring-2020/one-pitch-at-a-time/world-series.svg';

import Coaches from '../../images/spring-2020/one-pitch-at-a-time/coaches.jpg';
import championship from '../../images/spring-2020/one-pitch-at-a-time/1995-championship.jpg';
import seventyfiveteam from '../../images/spring-2020/one-pitch-at-a-time/1975-team.jpg';

import gallery1 from '../../images/spring-2020/one-pitch-at-a-time/gallery/1.jpg';
import gallery2 from '../../images/spring-2020/one-pitch-at-a-time/gallery/2.jpg';
import gallery3 from '../../images/spring-2020/one-pitch-at-a-time/gallery/3.jpg';
import gallery4 from '../../images/spring-2020/one-pitch-at-a-time/gallery/4.jpg';
import gallery5 from '../../images/spring-2020/one-pitch-at-a-time/gallery/5.jpg';
import gallery6 from '../../images/spring-2020/one-pitch-at-a-time/gallery/6.jpg';
import gallery7 from '../../images/spring-2020/one-pitch-at-a-time/gallery/7.jpg';
import gallery8 from '../../images/spring-2020/one-pitch-at-a-time/gallery/8.jpg';

import socialBanner from "../../images/spring-2020/social-banners/one-pitch-at-a-time.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      introPlayed: false,
      width: null,
      height: null,
      mute: false
    };
    // this.introVideo = React.createRef();
    // this.introVideoControls = React.createRef();
    // this.introSound = React.createRef();
    // this.introContainer = React.createRef();
    // this.introVideoReplay = React.createRef();
    // this.IntroEnd = this.IntroEnd.bind(this);
    // this.IntroSoundEnd = this.IntroSoundEnd.bind(this);
    // this.toggleSound = this.toggleSound.bind(this);
    // this.CloseIntro = this.CloseIntro.bind(this);
    // this.IntroSoundStatus = this.IntroSoundStatus.bind(this);
    // this.introReplay = this.introReplay.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    // this.introVideo.current.addEventListener("ended", this.IntroEnd);
    // this.introSound.current.addEventListener("ended", this.IntroSoundEnd);
    // this.introSound.current.addEventListener("play", this.IntroSoundStatus);
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    // this.introVideo.current.removeEventListener('ended', this.IntroEnd);
    // this.introSound.current.removeEventListener('ended', this.IntroSoundEnd);
    // this.introSound.current.removeEventListener('play', this.IntroSoundStatus);
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    this.setState({ height: window.innerHeight });
  };

  // IntroEnd() {
  //   // alert('hello world')

  //   const currentState = this.state.introPlayed;
  //   this.setState({ introPlayed: !currentState });
  // }

  // IntroSoundEnd() {
  //   this.introContainer.current.remove();
  //   this.introVideoControls.current.remove();
  // }

  // CloseIntro() {
  //   this.introContainer.current.remove();
  //   this.introVideoControls.current.remove();

  //   // this.introSound.current.muted = true;
  //   // const currentState = this.state.introPlayed;
  //   // this.setState({ introPlayed: !currentState });
  // }

  // toggleSound() {
  //   if(this.introSound.current.muted) {
  //     this.introSound.current.muted = false;
  //     this.setState({ mute: false });
  //   } else {
  //     this.introSound.current.muted = true;
  //     this.setState({ mute: true });
  //   }
  // }

  // IntroSoundStatus() {
  //   if(this.introSound.current.muted) {
  //     this.setState({ mute: true });
  //   }
  // }

  // introReplay() {

  // }

  render() {
    
    var pageTitle = 'One Pitch at a Time';
    var pageDesc = 'Since the university became a National Collegiate Athletic Association Division 1 program in 1975, Titans have racked up 45 winning baseball seasons, resulting in 18 appearances in the College World Series and four national championships.';


    const photos = [
      {
        src: gallery1,
        width: 800,
        height: 991,
        alt: 'College World Series, 1995',
        title: 'College World Series, 1995'
      },
      {
        src: gallery2,
        width: 800,
        height: 571,
        alt: 'Jack Jones, 1995',
        title: 'Jack Jones, 1995'
      },
      {
        src: gallery3,
        width: 800,
        height: 1200,
        alt: 'Tanner Bibee, 2020',
        title: 'Tanner Bibee, 2020'
      },
      {
        src: gallery4,
        width: 800,
        height: 533,
        alt: 'Kameron Guangorena, 2020',
        title: 'Kameron Guangorena, 2020'
      },
      {
        src: gallery5,
        width: 800,
        height: 560,
        alt: 'Phil Nevin, 1992',
        title: 'Phil Nevin, 1992'
      },
      {
        src: gallery6,
        width: 800,
        height: 1202,
        alt: 'Michael Lorenzen, 2011-13',
        title: 'Michael Lorenzen, 2011-13'
      },
      {
        src: gallery7,
        width: 603,
        height: 800,
        alt: 'Phil Nevin, 1992',
        title: 'Phil Nevin, 1992'
      },
      {
        src: gallery8,
        width: 800,
        height: 648,
        alt: 'Augie Garrido, 1984',
        title: 'Augie Garrido, 1984'
      }
    ];

    var bannerstyles = {
      width: this.state.width,
      height: this.state.height
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="one-pitch-at-a-time" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <Parallax
        bgImage={HeroBG}
        bgImageAlt="Baseball game illustration"
        strength={300}
        className="full-width parallax-hero animated fadeIn">
          <div className="hero-text">
            <h1 className="animated flipInX delay-1s"><img src={Title} alt={pageTitle} /></h1>
            <p className="sub-heading animated flipInY delay-1s">From Goodwin Field to the Big Leagues, Titan Baseball Hits It Out of the Park</p>
            <p className="author animated flipInY delay-1s">Story by Debra Cano Ramos, Illustrations by Cuyler Smith,<br />Photos by Matt Brown and Titan Athletics</p>
            <DownArrow color="white" />
          </div>
          <div className="height" />
        </Parallax>

        {/* <section className="feature-hero animated fadeInDown">
          <div className="hero-text">
            <h1 className="animated flipInX delay-1s"><img src={Title} alt={pageTitle} /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">From Goodwin Field to the Big Leagues, Titan Baseball Hits It Out of the Park</p>
            <p className="author animated fadeInDown delay-1s">Story by Debra Cano Ramos ’84, Illustrations by Cuyler Smith ’09</p>
          </div>
        </section> */}

        <article className="wrap small" id="intro">
          
          <ScrollAnimation animateIn="fadeIn">
            
          <p className="intro-paragraph"><span className="intro-text">In elementary school</span>, Dustin Garneau wrote a letter to his future self: He had big dreams to play in the big leagues — in the National Football League or Major League Baseball.</p>

          <Related slug="one-pitch-at-a-time" />
          
          <p>The high school star athlete was headed to play football in college — that is until Coach Rick Vanderhook offered him the opportunity to play ball at Cal State Fullerton. The young athlete stepped up to the plate, eager to become part of the legacy of Titan baseball.</p>

          <p>“When I got the offer, I absolutely jumped on it. It’s the best program in the country. Plain and simple,” Garneau declares.</p>
          <p>Garneau started behind the plate at Goodwin Field during the 2006 season and is now a catcher with the Houston Astros. The major leaguer is among the long list of players and coaches who have built Titan baseball into one of the elite programs in the nation.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={Garneau} alt="Dustin Garneau running bases in Angel's gear" />
            <figcaption>Dustin Garneau</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Its storied history began as an upstart program among the orange groves of Fullerton and today, is one of the premier programs in the country.</p>
          
          <figure className="align-right large algin-outside circle">
            <AnchorLink href='#stats' className="by-the-numbers">
              <img src={byTheNumbers} alt="Titan Baseball by the Numbers" />
            </AnchorLink>
          </figure>

          <p>Since the university became a National Collegiate Athletic Association Division 1 program in 1975, Titans have racked up 45 winning baseball seasons, resulting in 18 appearances in the College World Series and four national championships.</p>

          <p>The program touts 30 Big West Conference Championships, 67 alumni in Major League Baseball and three Golden Spikes winners, an honor given by USA Baseball to the top amateur baseball player in the nation: Tim Wallach in 1979, Phil Nevin in 1992 and Mark Kotsay in 1995.</p>
          </ScrollAnimation>
        </article>
        <ScrollAnimation animateIn="fadeIn">
          <figure className="full-width gallery">
            <Gallery photos={photos}  />
            <figcaption>
            College World Series, 1995; Jack Jones, 1995; Tanner Bibee, 2020; Kameron Guangorena, 2020; Phil Nevin, 1992; Michael Lorenzen, 2011-13; Phil Nevin, 1992; Augie Garrido, 1984
            </figcaption>
          </figure>
          </ScrollAnimation>

        <article className="wrap small">
        <ScrollAnimation animateIn="fadeIn">
          <p>Titan players have always competed against the best teams in the country. Now it’s time to upgrade the baseball facilities to match competitors' stadiums, and to remain competitive and recruit the best athletes. Construction to build a new state-of-the-art clubhouse is expected to begin this summer.</p>
          <p>“Baseball helped to put Cal State Fullerton on the map,” says Steve DiTolla, senior associate director of athletics. “It’s our brand. When we travel across the country and wear ‘Fullerton’ on our hats, it’s synonymous with having a great university and a great baseball team.”</p>
          <p>George Horton, Titan head coach from 1997 to 2007 who has earned such national coaching honors as Baseball America Coach of the Year, points out the simple reason for the program’s success.</p>
          <p>“It’s the consistency of the baseball program that sets Fullerton apart — from the spectacular first year in Division I in 1975 to today.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>1,544 Miles to Omaha</h2>
          <p>The university launched its intercollegiate baseball program in 1965, when part of an orange grove was turned into a playing field. A decade later, under the late Coach Augie Garrido, the new Division I team made its first coveted appearance at the College World Series in Omaha, Nebraska. The Titans beat top-ranked programs in the region to advance to the baseball tournament, upsetting five-time national champion USC on the Trojans’ home field.</p>
          </ScrollAnimation>
          </article>

          {/* <figure className="large align-right">
            <img src={seventyfiveteam} alt="1975 Team Photo" />
            <figcaption>1975 Team</figcaption>
          </figure> */}

          <ScrollAnimation animateIn="fadeIn">
          <Parallax
          bgImage={seventyfiveteam}
          bgImageAlt="1975 Team Photo"
          strength={200}
          className="full-width">
            <div className="caption">
            1975 team
            </div>
            <div className="height" />
          </Parallax>
          </ScrollAnimation>

        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p>“It was an amazing accomplishment,” beams Horton, first baseman on the 1975 team. He reminisces that Garrido even bought blazers for the players to make their momentous arrival 1,544 miles away at Johnny Rosenblatt Stadium.</p>

          <p>Becoming a program known for “baseball excellence” is because of a “David versus Goliath” mentality, adds Horton, part of the coaching staff that led the Titans to win the national championship in 1995, and again in 2004 as head coach.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={championship} alt="1995 Championship" />
            <figcaption>1995 championship team</figcaption>
          </figure>
          </ScrollAnimation>
        </article>

        <ScrollAnimation animateIn="fadeIn">
        <Parallax
          className="full-width video-bg"
          strength={200}>
          <div className="parallax-text" id="intro">
          <p>“From 1975 to present, the program and players have achieved greatness without having the same resources as our competitors,” notes Horton, who has had a legendary career as a college baseball coach for more than 40 years. He most recently spent 11 seasons as head coach at the University of Oregon.</p>

          <p>Grit, determination and overcoming all odds have elevated the program to one of the best in the country, Horton believes. “It’s been a blue-collar program from the start. The program continues to win at the highest level and develops the best college baseball players in the country.”</p>
          </div>
          <div className="height" />
          <Background className="custom-bg">
            <video id="heroVideo" autoPlay="autoplay" loop muted="muted" poster={withPrefix('/video/baseball-background.jpg')} >
              <source src={withPrefix('/video/baseball-background.webm')} type="video/webm; codecs=vp9,vorbis" />
              <source src={withPrefix('/video/baseball-background.mp4')} />
              <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/baseball-background.vtt')} />
            </video>
          </Background>
        </Parallax>
        </ScrollAnimation>
        
        <article className="wrap small">
          <ScrollAnimation animateIn="fadeIn">
          <h2>Titans in the Big Show: The MLB</h2>
          <p>For some players, their baseball careers don’t end at Goodwin Field. The program builds up players to take their game to the next level: the Major Leagues.</p>
          <p>This season’s roster and scores of alumni like Garneau have learned from the best Titan coaches in NCAA baseball history: Garrido, Horton, Larry Cochell, Dave Serrano and Vanderhook, head coach since 2011.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={Coaches} alt="Portraits of: Augie Garrido, George Horton, Larry Cochell, Dave Serrano and Rick Vanderhook" />
            <figcaption>Augie Garrido, George Horton, Larry Cochell, Dave Serrano and Rick Vanderhook</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Five former Titan players made appearances in the 2019 postseason — the most alumni on playoff rosters: catcher Kurt Suzuki of the World Series champions, the Washington Nationals; third baseman Justin Turner of the Los Angeles Dodgers; relief pitcher Chris Devenski of the Astros; and Oakland A’s third baseman Matt Chapman and outfielder Khris Davis.</p>
          <p>Joining them in last year’s MLB playoffs were four former Titan players, now coaches for major league teams. Nevin, Mike Harkey and P.J. Pilittere are coaches with the New York Yankees, and Kotsay is with the A’s.</p>
          <p>Turner made his MLB debut with the Baltimore Orioles in 2009 and joined the Dodgers in 2014. He was recruited by Wallach, now the Dodgers’ bench coach who saw him hitting at a Cal State Fullerton alumni game.</p>
          <p>In a recent interview, Turner shared what it takes to be a Titan ballplayer: “It’s a guy who cares about what happens for nine innings between the white lines — and winning ball games. That’s what makes the program so successful.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="zoomIn">
          <YouTube
            containerClassName="align-wide video"
            videoId="A427THYlxUE"
            opts={{
              height: '478',
              width: '850',
              playerVars: {
                rel: 0
              },
            }}
          />
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Garneau was a 19th-round draft pick in 2009 by the Colorado Rockies and made his first appearance in the MLB with the team in 2015. He’s played for the Oakland Athletics, Chicago White Sox, and spent last season close to home with the Los Angeles Angels. This season, he’s wearing No. 13 for the Astros.</p>
          <p>“It’s been a wild ride,” says Garneau. “Crazy how my dream came true.”</p>
          <p>Garneau makes his home in Orange County, and swings by the field during the off season to visit with players and the coaches who’ve become lifelong friends: “I try to give advice to the players to benefit them. But the coaching staff has a pretty good handle on how to make their players better.”</p>
          <p>As a Titan, Garneau played in the College World Series not just once, but three times — an “unbelievable experience” and the pinnacle of his collegiate baseball career. His time on the field, and in the classroom, prepared him for the big show.</p>
          <p>“Being at Fullerton was life-changing and definitely got me ready and prepared to play in the minors all the way up to the big leagues,” Garneau says. “When you play at Fullerton, you learn the fundamentals of baseball. The coaches instill a culture of hard work, of no-nonsense baseball. There are no tricks up our sleeves; that’s just how it’s always been done.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>A Clubhouse of the Future</h2>
          <p>Back in the 1970s and ’80s, there was no baseball locker room, no lights for the field, only two batting cages and a chain link fence that separated the outfield and the orange groves. “Players dressed in the parking lot and we still won national championships,” Horton laughs.</p>
          <p>It wasn’t until April 18, 1992, when “Titan Field” was built and hosted its first baseball game. The Titans won both games in a doubleheader against Loyola Marymount — setting the stage for winning seasons for the next two decades. A few weeks later, during a “Field of Dreams” ceremony, Oscar winner and Class of ‘78 Titan Kevin Costner threw out the first ball before 2,000 fans, who watched the Titans beat the UCI Anteaters 8-2.</p>
          <p>In 2000, an expansion added 3,500 seats and coaches’ offices, and the stadium was renamed in honor of benefactors Jerry and Merilyn Goodwin. A modest locker room for players also was built beneath the stands, which has two restrooms, a shower and wooden lockers for the 35 players on the roster each season.</p>
          <p>The $13.1 million upgrade project includes building the new two-story baseball clubhouse plus modernizing the existing baseball and softball facilities for the history-making softball program, which boasts four consecutive Big West Conference titles. Completion of the project is expected during the 2021 season.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={BaseballRendering} alt="Baseball building rendering" />
            <figcaption>Rendering of the entrance to the renovated baseball and softball clubhouse facilities</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>The existing baseball and softball clubhouse, located between Goodwin Field and Anderson Family Field, which includes the Doubek V.I.P. Room, will get a makeover with modernized softball locker rooms, coaches' offices and training room.</p>

          <p>A new 4,500-square-foot clubhouse, situated in the left field area of Goodwin Field, will sport a grand entrance into the baseball and softball stadiums and a ticket office. A statue of Garrido — the all-time Division I winner at the height of his career — will greet fans and an upstairs reception area will celebrate the history of Titan baseball.</p>
          </ScrollAnimation>

          {/* <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={SoftballRendering} alt="Softball building rendering" />
            <figcaption>Softball building rendering</figcaption>
          </figure>
          </ScrollAnimation> */}

          <ScrollAnimation animateIn="fadeIn">
          <p>“The players deserve to be treated in a first-class manner,” Horton points out about the need for the upgrades. “They sacrifice so much for the benefit of the university and community.”</p>
          <p>The new clubhouse will feature second-floor window offices for coaches and administrators, plus a terrace to overlook the field — and coaches will get their own locker room. An updated locker room for players, with 10 showerheads, is planned on the first floor. The space also has a kitchen and snack area, as well as space for the athletes to study, hang out and charge their laptops and phones. Meeting rooms, including rooms where players can watch game videos, also are planned.</p>
          </ScrollAnimation>
          </article>

          <ScrollAnimation animateIn="fadeIn">
        <Parallax
          className="full-width video-bg"
          strength={200}>
          <div className="parallax-text text-right" id="intro">
          <p>Vanderhook, a five-time Big West Conference Coach of the Year who served as an assistant coach for 21 seasons before taking the helm as head coach, couldn’t be happier to have a roomier, modern clubhouse.</p>
          <p>“This new facility is taking us into the 21st century and beyond,” Vanderhook says, sharing that his current undersized office doubles as his locker room and team meeting place.</p>
          </div>
          <div className="height" />
          <Background className="custom-bg">
            <video id="heroVideo" autoPlay="autoplay" loop muted="muted" poster={withPrefix('/video/goodwin-field.jpg')} >
              <source src={withPrefix('/video/goodwin-field.webm')} type="video/webm; codecs=vp9,vorbis" />
              <source src={withPrefix('/video/goodwin-field.mp4')} />
              <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/goodwin-field.vtt')} />
            </video>
          </Background>
        </Parallax>
        </ScrollAnimation>

        <article className="wrap small">
        <ScrollAnimation animateIn="fadeIn">
          <p>A player on the university’s 1984 national championship team, Vanderhoook helped lead Cal State Fullerton to two national championships as assistant coach in 1995 and 2004. This spring marks his 30th season as a Titan and his ninth season as head coach.</p>
          <p>Under his watch, Titans have made two College World Series appearances and earned four Big West Conference titles. Earlier this year, Vanderhook recorded his 300th win as head coach with a victory over Stanford.</p>

          <p>While the new clubhouse may be a hit in attracting new players, Vanderhook’s vision for the team remains the same.</p>

          <p>“Our goal is to play for a national championship — one pitch, one game at a time.” <span className="end-mark" /></p>

          <p className="learn-more">To learn more about Titan baseball, visit <a href="https://www.fullertontitans.com" target="_blank">fullertontitans.com</a>.</p>
          </ScrollAnimation>
          </article>
        
        <div className="grass-footer" />
        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
        <section className="stats" id="stats">
          <img src={f} alt="Fullerton Titans logo" className="logo" />
          <div className="wrap">
            <div className="row">
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown">
                  <img src={winningSeasons} alt="45 winning seasons" />
                </ScrollAnimation>
              </div>
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown" delay="300">
                  <img src={worldSeries} alt="18 College World Series appearances" />
                </ScrollAnimation>
              </div>
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"  delay="600"><img src={bigWest} alt="30 Big West Conference Championships" /></ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={alumni} alt="67 alumni in Major League Baseball" /></ScrollAnimation>
              </div>
              <div className="col-5">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={allAmericans} alt="116 All-Americans" /></ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={championships} alt="4 National Championships: 1979, 1984, 1995, 2004" /></ScrollAnimation>
              </div>
              <div className="col-5">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={goldenSpikes} alt="3 Golden Spikes Winners" /></ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={drafted} alt="Most Titans Drafted in a Single Year: 14 in 2005" /></ScrollAnimation>
              </div>
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={ncaa} alt="40 NCAA Regional Appearances" /></ScrollAnimation>
              </div>
              <div className="col-4">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={coachAwards} alt="7 National Coach of the Year Awards" /></ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={stadium} alt="Stadium: Goodwin Field" /></ScrollAnimation>
              </div>
              <div className="col-6">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={colors} alt="Colors: Navy Blue, White and Oranve" /></ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={headCoach} alt="Head Coach: Rick Vanderhook" /></ScrollAnimation>
              </div>
              <div className="col-6">
                <ScrollAnimation animateOnce="true" animateIn="fadeInDown"><img src={nickname} alt="Nickname: Titans" /></ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        </ScrollAnimation>
        <MoreStories slug="one-pitch-at-a-time" />
        <StoryNav slug="one-pitch-at-a-time" />

        <BaseballIntro />
      </Layout>
    );
  }
} 