import './baseball-intro.scss';
import React from "react";
import { withPrefix } from 'gatsby'
import { CSSTransition } from 'react-transition-group';
import Icon from '../../components/icons';
import { TransitionPortal } from "gatsby-plugin-transition-link";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      introPlayed: false,
      mute: true,
      width: null,
      height: null,
    };
    this.introVideo = React.createRef();
    this.introVideoControls = React.createRef();
    this.introSound = React.createRef();
    this.introContainer = React.createRef();
    this.introVideoReplay = React.createRef();
    this.IntroEnd = this.IntroEnd.bind(this);
    this.IntroSoundEnd = this.IntroSoundEnd.bind(this);
    this.toggleSound = this.toggleSound.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.CloseIntro = this.CloseIntro.bind(this);
    // this.IntroSoundStatus = this.IntroSoundStatus.bind(this);
    // this.introReplay = this.introReplay.bind(this);
  }

  componentDidMount() {
    this.introVideo.current.addEventListener("ended", this.IntroEnd);
    this.introSound.current.addEventListener("ended", this.IntroSoundEnd);
    // this.introSound.current.addEventListener("play", this.IntroSoundStatus);
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.introVideo.current.removeEventListener('ended', this.IntroEnd);
    this.introSound.current.removeEventListener('ended', this.IntroSoundEnd);
    // this.introSound.current.removeEventListener('play', this.IntroSoundStatus);
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    this.setState({ height: window.innerHeight });
  };

  IntroEnd() {
    // alert('hello world')

    const currentState = this.state.introPlayed;
    this.setState({ introPlayed: !currentState });
  }

  IntroSoundEnd() {
    this.introContainer.current.remove();
    this.introVideoControls.current.remove();
  }

  CloseIntro() {
    this.introContainer.current.remove();
    this.introVideoControls.current.remove();

    // this.introSound.current.muted = true;
    // const currentState = this.state.introPlayed;
    // this.setState({ introPlayed: !currentState });
  }

  toggleSound() {
    if(this.introSound.current.muted) {
      this.introSound.current.muted = false;
      this.setState({ mute: false });
    } else {
      this.introSound.current.muted = true;
      this.setState({ mute: true });
    }
  }

  // IntroSoundStatus() {
  //   if(this.introSound.current.muted) {
  //     this.setState({ mute: true });
  //   }
  // }

  // introReplay() {

  // }

  render() {
    var bannerstyles = {
      width: this.state.width,
      height: this.state.height
    }

    return (
        <React.Fragment>
          <TransitionPortal level="top">
            <div ref={this.introVideoControls} className="intro-video-controls" >
              <button onClick={this.CloseIntro}><Icon name="close" alt="Close Icon" /> Close</button>
              <button onClick={this.toggleSound}><Icon name={this.state.mute ? 'sound': 'mute'} alt={this.state.mute ? 'Sound On Icon': 'Mute Icon'} /> {this.state.mute ? 'Sound On': 'Sound Off'}</button>
            </div>
          </TransitionPortal>
          <CSSTransition
          in={this.state.introPlayed}
          classNames={{
            enter: "animated",
            enterActive: "animated fadeOut",
            enterDone: "animated fadeOut",
            exit: "animated fadeOut",
            exitActive: "animated fadeOut",
            exitDone: "animated fadeOut",
          }}
          >
          <div id="intro-video-container" ref={this.introContainer}>  
            <video preload="auto" ref={this.introVideo} id="heroVideo" autoPlay="autoplay" muted="muted" poster={withPrefix('/video/baseball-hero.jpg')}>
              <source src={withPrefix('/video/baseball-hero.webm')} type="video/webm; codecs=vp9,vorbis" />
              <source src={withPrefix('/video/baseball-hero.mp4')} />
              <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/baseball-hero.vtt')} />
            </video>
            <audio preload="auto" muted="muted" ref={this.introSound} autoPlay="autoplay" src={withPrefix('/audio/baseball-intro.mp3')}>
              <track kind="descriptions"  src={withPrefix('/audio/baseball-intro.html')} />
            </audio>
            
          </div>
          </CSSTransition>
        </React.Fragment>
    );
  }
} 